import React from "react"
import {Box,Container} from "@mui/material";

function PageFooter () {
    return (

<div className="terms">

<Container>
    



<p className="terms">Disclaimer: This is an ADVERTISEMENT. Heard & Smith is not affiliated with nor 
endorsed by the Social Security Administration or any other government agency. 
Thousands of Americans from around the country trust Heard & Smith to be their law firm 
representing them on their disability claim. 
We're honored that we've helped thousands of deserving Americans get justice, 
and a monthly disability benefit check.
</p>


<p>This website should not be taken as legal advice and does not create an attorney-client
 relationship. 
</p>


<p>Heard & Smith offers a no obligation, free evaluation to see if you qualify for benefits. 
Contact us today if you or a loved one cannot work because of medical problems. 
If you qualify there are never any fees upfront to hire us and get our 
attorneys assisting you with your claim. 
You only pay a fee if you are awarded benefits and if you are awarded backpay. 
</p>
<hr></hr>
<p className="terms">Copyright © 2022 heard & Smith</p>



</Container>



</div> 

);
}

export default PageFooter;