import React , {useState} from "react"
import {Container, Grid} from "@mui/material";

function TYCopy (props) {
 
 //console.log(props)
 
 
    return (

        <Container>
        <h1>        Thank You</h1>
        <h3>You may qualify for up to $3,345 per month in Social Security Disability benefits</h3>
        <h3>Apply now by phone <a href="tel:877-435-3737"> (877) 435-3737</a></h3>

        <Grid container spacing ={2}>
            <Grid item xs={12} sm={4}>
                <h4>Here's What's Next:</h4>
                <p>A Disbility Benefits Specialist will be contacting you shortly.  
            </p>
            
            </Grid>
            </Grid>
    <hr></hr>
            </Container>


);
}

export default TYCopy;

