import React from "react"
import {Container, Grid} from "@mui/material";

function LowerBody () {
    return (

        <Container>
        
        <div className="footerbox">
            <h3>    Your Free Disability Evaluation Is Just Minutes Away!</h3>
            <br/>
        </div>
        
         <Grid container spacing ={2}>


            <Grid item xs={12} sm={4}>
                <h4>Over 50? </h4>
                <p>Social Security’s rules may make it easier for you to qualify for your benefits. We have experience with these rules that can help your claim. </p>
            </Grid>


            
            <Grid item xs={12} sm={4}>
                <h4>Collect What Is Yours</h4>
                <p>Social Security Disability is an earned benefit, like our Social Security Retirement. You paid for these benefits from the FICA taxes that were taken out of each of your paychecks over the years. If you cannot work because of medical problems you could be entitled to your disability benefits.  </p>
            </Grid>
            
            
            <Grid item xs={12} sm={4}>
                <h4>Don’t File On Your Own</h4>
                <p>Most who apply for disability will be denied. Social Security’s own data says those who have an experienced representative on their claim are more likely to win benefits. The stakes are too high. Your benefits are worth too much, you do not want to make mistakes that could delay your case. You deserve an experienced representative. </p>
            </Grid>
            
            



           
        </Grid>
        
            {/* <Grid item xs={12} sm={4}>
                <h4>Don’t File On Your Own</h4>
                <p>Most who apply for disability will be denied. Social Security’s own data says those who have an experienced representative on their claim are more likely to win benefits. The stakes are too high. Your benefits are worth too much, you do not want to make mistakes that could delay your case. You deserve an experienced representative. </p>
            </Grid> */}
              <hr></hr>
            </Container>


);
}

export default LowerBody;