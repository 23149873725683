

import React from "react"
import {Box,Container} from "@mui/material";

function Terms () {
    return (
<div>

<Container>

<p className="terms">

By submitting this form, I provide my express consent to receive phone calls, 
sms or text messages, and emails from or on behalf of Heard & Smith, LLP on 
the residential or cellular number provided to see if I am eligible for 
disability benefits, even if I’m on a Federal or State Do Not Call list or 
email registry. I understand these calls may be generated using an automated 
telephone dialing system and may contain pre-recorded messages and that consenting is 
not required to utilize our service or as a condition of purchasing any products or 
services, and that I can revoke this consent at any time. Cellular rates may apply.

 

  </p>
</Container>
</div>

   )
  }


export default Terms