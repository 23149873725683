
import React from "react"
import {Grid, Container,Box,AppBar, Toolbar } from "@mui/material";
import logo from "../img/d-over-50-logo.png";
import Blogo from "../img/benefits-logo.png";

import { Button, LinearProgress } from '@mui/material';
import { DriveEtaOutlined } from "@mui/icons-material";


function PageHeader () {
    return (


<div>

      





        <Box className="topbar">
          
                            

   
<Button 

sx={{

  float: "right",
  display: "block",
  textAlign: "center"
}}
href="tel:877-435-3737" variant="contained" size="large" color="warning" >
              Apply by phone  (877) 435-3737 
  </Button>
        



          <img src={Blogo}/>
         
     </Box>
        
    <div className="topbarspace">
      
    </div>

</div>


);
}

export default PageHeader;