import React from "react"
//import {Container, Grid,MenuItem, Paper, Select, LinearProgress} from "@mui/material";
import Box from '@mui/material/Box';
import { Routes, Route, Link, useNavigate} from "react-router-dom";


//import TextField from '@mui/material/TextField';
import { Button, LinearProgress } from '@mui/material';


import { Formik, Form, Field } from 'formik';
//import { TextField } from 'formik-mui';
import {
  Container,
  Grid,
  Typography
} from '@mui/material';
import Select from './FormsUI/Select';
import ReactGA from "react-ga4";
//import {Container, Grid,MenuItem, Paper, Select} from "@mui/material";

//import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Textfield from './FormsUI/Textfield';

import countries from '../data/by.json';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  country: ''
  
};

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email.')
    .required('Required'),
  phone: Yup.number()
    .integer()
    .typeError('Please enter a valid phone number. just the numbers no spaces, -, or () ')
    .required('Required'),
  country: Yup.string()
    .required('Required'),
 
});





function LeadForm (props) {


  const navigate = useNavigate();

console.log(props);

    return (

      <Grid container>
      <Grid item xs={12}>
        
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="lg">
          <div>

            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              validationSchema={FORM_VALIDATION}


              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                //alert(JSON.stringify(values, null, 2));
                //alert(values.firstName);

                const data={
 
 
                  vendorToken: "hs-suite-215",
                  campaignId: props.src,
                  vendorLeadId:"lp29999",
                  first: values.firstName,
                  last:values.lastName,
                  address1:" ",
                  address2:" ",
                  city:" " ,
                  state:" ",
                  zip:" ",
                  email: values.email,
                  phone:values.phone,
                  dob:" ",
                  age:" ",
                  note:"Birth year: "+values.country
               }

               
               const url = "https://api.heardandsmith.com/lead";

/*                            

              fetch(url, {
                method: 'POST', // or 'PUT'
                headers: {
                  'Content-Type': 'application/json',
                },
                mode: 'no-cors',
                body: JSON.stringify(data),
              })
                .then((response) => response.text())
                .then((data) => {
                  console.log('Success:', data);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });

*/



              // alert(JSON.stringify(data, null, 2));


                ReactGA.initialize("G-S6VTL4XE7W");

                ReactGA.send({ hitType: "pageview", page: "/thankyou" });
                  //ReactGA.send("pageview");
                  ReactGA.event({
                    category: "Leads",
                    action: "generate_lead",
                    label: "lp2 lead", // optional
                  
                  });


                navigate("/thankyou/",{src: "nv"})
              }}

            >
              <Form >
              
              
              

                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <Typography>
                      Your details {props.src}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Textfield
                      name="firstName"
                      label="First Name"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Textfield
                      name="lastName"
                      label="Last Name"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Textfield
                      name="email"
                      label="Email"
                      type="email"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Textfield
                      name="phone"
                      label="Phone"
                      type = "tel"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Select
                      name="country"
                      label="Birth Year"
                      options={countries}
                    />
                  </Grid>

                

                  <Grid item xs={12}>
                    <Button variant="contained" type="submit" color="warning">
                      Do I Qualify?
                    </Button>
                  </Grid>


                </Grid>

              </Form>
            </Formik>

          </div>



       

        </Container>
      </Grid>
    </Grid>

);
}

export default LeadForm;
