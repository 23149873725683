
import React from "react"
import {Container} from "@mui/material";
import BodyCopy from "../components/BodyCopy";
import LeadForm from "../components/LeadForm";
import PageFooter from "../components/PageFooter";
import PageHeader from "../components/PageHeader";
import LowerBody from "../components/lowerBody"
import { useParams } from "react-router-dom";
import Terms from "./Terms";
import { string } from "yup";
import { PinDropSharp } from "@mui/icons-material";


function HomeB () {

  let params=useParams();
  let src="BDEF" ;
 console.log(params.src)
 src=params.src;


 if (!params.src) {
src="A-DEF"
} else {
src=params.src;
}


console.log(src);


  return (

<div>

  <PageHeader/>
  <BodyCopy/>
  <LeadForm  src={src}  />
  <Terms/>
  <LowerBody />
 

  <PageFooter/>


</div>
);
}

export default HomeB;