import React from "react"
import { BrowserRouter as Router} from "react-router-dom";
import { Routes, Route, Link, useNavigate, useParams} from "react-router-dom";
//import {Container, Grid} from "@mui/material";
import './App.css';
import BodyCopy from "./components/BodyCopy";
import LeadForm from "./components/LeadForm";
import PageFooter from "./components/PageFooter";
import PageHeader from "./components/PageHeader";
import Home from "./components/Home";
import HomeB from "./components/Home-B";
import ThankYou from "./components/ThankYou";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


//import Branded from "./pages/branded.js";
//mport UnBranded from "./pages/unbranded.js";

//import BodyBranded from "./components/body-branded";

function App() {
 
 
  return (
    <Router>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a/:src" element={<Home />} />
        <Route path="/a/" element={<Home />} />
        <Route path="/b/:src" element={<HomeB />} />
        <Route path="/b/" element={<HomeB />} />
        
        
        <Route path="/thankyou" element={<ThankYou />} />

        </Routes>
    
  </Router>
  );
}

export default App;