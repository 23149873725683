import React from "react"
import {Container,Box, Grid, Button, Typography} from "@mui/material";

function BodyCopy () {
    return (

        <Container >
<Typography>
    <Box sx={{topMargin:"100px"}}>
        <h1>Free Social Security Disability Evaluation</h1>
        <h3>You may qualify for up to $3,345 per month in Social Security Disability benefits</h3>
        
      
        {/* <Button href="tel:877-435-3737" variant="contained" size="large" color="warning" >
            Apply by phone  (877) 435-3737 
        </Button> */}
        
<a href="tel:877-435-3737"> </a>
</Box>    
        <hr></hr>
        <Grid container spacing ={2}>


            {/* <Grid item xs={12} sm={4}>
                <h4>Collect What Is Yours</h4>
                <p>Social Security Disability is an earned benefit, like our Social Security Retirement. You paid for these benefits from the FICA taxes that were taken out of each of your paychecks over the years. If you cannot work because of medical problems you could be entitled to your disability benefits.  </p>
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
                <h4>Over 50? </h4>
                <p>Social Security’s rules may make it easier for you to qualify for your benefits. We have experience with these rules that can help your claim. </p>
                </Grid> */}
        
        
        

        <Grid item xs={12} sm={4}>
            
            <h4>Who Is Eligible?</h4>
            <p>If you are not working because of medical issues, you could be entitled to receive a cash benefit each month (plus health insurance, and a lump sum back pay award.) The lifetime average value of disability benefits could be up to several hundred thousand dollars, depending on age and past earnings.  
        </p>
        
        </Grid>

            <Grid item xs={12} sm={4}>
                <h4>Free Evaluation</h4>
                <p>Find out if you are eligible. Our no obligation free evaluation will consider your medical problems and eligibility for disability benefits. If you qualify, we can help you file your application and represent you throughout the process. Leave the hassles and confusion of dealing with the Social Security office to our experienced team. No fees to get started, and no fees unless you win your benefits. </p>
            </Grid>

    
            <Grid item xs={12} sm={4}>
                <h4>Those Who Qualify May Receive</h4>
                <p>Up to $3,345 a month in cash disability benefits. Health insurance (Medicare, Medicaid, or both.) A lump sum back-payment award. Annual Cost of Living (COLA) increase to disability benefits payment. Our Attorneys helping you on your claim for no out of pocket costs to you (and never any fees unless you win.)  </p>
            </Grid>
           </Grid>
                <h3>    Submit this form for a free disability benefits evaluation:</h3>
                
                
                
                </Typography>
                
                
                </Container>


);
}

export default  BodyCopy;