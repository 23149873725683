
import React from "react"
import {Container, useThemeProps} from "@mui/material";
import BodyCopy from "../components/BodyCopy";
import LeadForm from "../components/LeadForm";
import PageFooter from "../components/PageFooter";
import PageHeader from "../components/PageHeader";
import TYCopy from "../components/ty-copy.js"
import useDocumentTitle from './useDocumentTitle'
import { useParams } from "react-router-dom";

function ThankYou () {
  //const { src } = route.params;
  //console.log(route)
    useDocumentTitle('Thank You - Disability Benefits Help')
    
    return (

<div>

  <PageHeader/>
  <TYCopy />
  <PageFooter/>


</div>
);
}

export default ThankYou;